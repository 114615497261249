// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-example-tsx": () => import("./../../src/pages/example.tsx" /* webpackChunkName: "component---src-pages-example-tsx" */),
  "component---src-templates-author-js": () => import("./../../src/templates/author.js" /* webpackChunkName: "component---src-templates-author-js" */),
  "component---src-templates-blog-tsx": () => import("./../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-category-tsx": () => import("./../../src/templates/category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-tag-js": () => import("./../../src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */)
}

